"use client";

import useVisitor from "@/lib/visitor/useVisitor";
import EventEmitter from "events";
import dynamic from "next/dynamic";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";

export class AuthenticationEventEmitter extends EventEmitter {
  static instance = new AuthenticationEventEmitter();

  constructor() {
    super();
  }

  static getInstance() {
    return this.instance;
  }
}

export const authenticate = ({ source }: { source: string }) => {
  // Emit "authenticate" signal
  AuthenticationEventEmitter.getInstance().emit("authenticate", { source });
  // Resolve on authenticated signal
  return new Promise((resolve, reject) => {
    AuthenticationEventEmitter.getInstance().once("authenticated", (data) => {
      resolve(data);
    });

    // Reject on cancel
    AuthenticationEventEmitter.getInstance().once("cancel", () => {
      reject();
    });
  });
};

const _AuthenticateModal = dynamic(() => import("./form"), {
  ssr: false,
  loading: () => null,
});

function AuthenticateModal() {
  const [isOpen, setIsOpen] = useState(false);
  const { visitor, isLoading } = useVisitor();

  const [source, setSource] = useState<string>("");

  const posthog = usePostHog();

  useEffect(() => {
    const listener = ({ source }: { source: string }) => {
      if (visitor && visitor.phoneVerified) {
        setTimeout(() => {
          AuthenticationEventEmitter.getInstance().emit(
            "authenticated",
            visitor
          );

          window.gtag("config", process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
            user_id: visitor.revnewContactId,
          });

          setIsOpen(false);
        }, 10);
        return;
      }

      setSource(source);

      setIsOpen(true);
    };

    AuthenticationEventEmitter.getInstance().on("authenticate", listener);

    return () => {
      AuthenticationEventEmitter.getInstance().off("authenticate", listener);
    };
  }, [isLoading, visitor, posthog]);

  if (!isOpen) return null;

  return <_AuthenticateModal setIsOpen={setIsOpen} source={source} />;
}

export default AuthenticateModal;
