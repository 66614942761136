import { Visitor } from "@/app/api/visitor/types";
import useSWR from "swr";

const useVisitor = () => {
  const { data, isLoading, mutate } = useSWR<Visitor | null>("/api/visitor/me");

  return {
    visitor: data,
    isLoading,
    mutate,
  };
};

export default useVisitor;
