"use client";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";

import useVisitor from "@/lib/visitor/useVisitor";
import { usePathname, useSearchParams } from "next/navigation";
import { usePostHog } from "posthog-js/react";

export default function PostHogPageView() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();
  useEffect(() => {
    // Track pageviews
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      posthog.capture("$pageview", {
        $current_url: url,
      });
    }
  }, [pathname, searchParams, posthog]);

  return null;
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  const { visitor } = useVisitor();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
        person_profiles: "identified_only",
        capture_pageview: false,
      });
    } else {
      console.warn("Posthog key not found");
    }
  }, []);

  useEffect(() => {
    if (!visitor) return;

    if (visitor?.phone) {
      // If the user is not identified, identify
      if (
        !posthog.get_property("$user_id") ||
        posthog.get_property("$user_id") !== visitor.phone
      ) {
        posthog.identify(visitor.phone, {
          email: visitor.email,
          phone: visitor.phone,
        });
      }
    } else {
      // If the user is already identified, reset
      if (posthog.get_property("$user_id")) {
        posthog.reset();
      }
    }
  }, [visitor]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
